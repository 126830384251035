// extracted by mini-css-extract-plugin
export var checkbox = "CreateCloudProjectIaasCloudStorageConfiguration__checkbox__WqNB5";
export var cloudType = "CreateCloudProjectIaasCloudStorageConfiguration__cloudType__t2aAa";
export var column = "CreateCloudProjectIaasCloudStorageConfiguration__column__IeJgv";
export var combinedFieldSelect = "CreateCloudProjectIaasCloudStorageConfiguration__combinedFieldSelect__cXlPt";
export var combinedFieldSelectWrapper = "CreateCloudProjectIaasCloudStorageConfiguration__combinedFieldSelectWrapper__eYLjv";
export var combinedFieldTextInput = "CreateCloudProjectIaasCloudStorageConfiguration__combinedFieldTextInput__NKklw";
export var combinedFieldsContainer = "CreateCloudProjectIaasCloudStorageConfiguration__combinedFieldsContainer__jfEeX";
export var combinedFieldsValidationMessage = "CreateCloudProjectIaasCloudStorageConfiguration__combinedFieldsValidationMessage__aoQgS";
export var connectivityMethod = "CreateCloudProjectIaasCloudStorageConfiguration__connectivityMethod__pMIBk";
export var field = "CreateCloudProjectIaasCloudStorageConfiguration__field__Cp8Wo";
export var fieldRow = "CreateCloudProjectIaasCloudStorageConfiguration__fieldRow__GnPEZ";
export var formPanelBodyContainer = "CreateCloudProjectIaasCloudStorageConfiguration__formPanelBodyContainer__X8pAK";
export var formSectionHeading = "CreateCloudProjectIaasCloudStorageConfiguration__formSectionHeading__CD87p";
export var horizontalLine = "CreateCloudProjectIaasCloudStorageConfiguration__horizontalLine__V8Z8W";
export var howItWorksTooltip = "CreateCloudProjectIaasCloudStorageConfiguration__howItWorksTooltip__Z7p25";
export var limitedWidthSelectField = "CreateCloudProjectIaasCloudStorageConfiguration__limitedWidthSelectField__cxf1b";
export var panelContainer = "CreateCloudProjectIaasCloudStorageConfiguration__panelContainer__aRcf_";
export var rawStorageCapacityFieldWrapper = "CreateCloudProjectIaasCloudStorageConfiguration__rawStorageCapacityFieldWrapper__AhkQh";
export var region = "CreateCloudProjectIaasCloudStorageConfiguration__region__NxLl8";
export var required = "CreateCloudProjectIaasCloudStorageConfiguration__required__nmDgh";
export var row = "CreateCloudProjectIaasCloudStorageConfiguration__row__nMWg4";
export var startDate = "CreateCloudProjectIaasCloudStorageConfiguration__startDate__v559v";
export var textAreaContainer = "CreateCloudProjectIaasCloudStorageConfiguration__textAreaContainer__xCz87";
export var title = "CreateCloudProjectIaasCloudStorageConfiguration__title__xX_aU";
export var tooltipAndHeadingWrapper = "CreateCloudProjectIaasCloudStorageConfiguration__tooltipAndHeadingWrapper__Fji4l";
export var tooltipDescription = "CreateCloudProjectIaasCloudStorageConfiguration__tooltipDescription__wdPQd";